import { ReactComponent as HeroVector } from '../../assets/img/hero-vector.svg';

import { ReactComponent as HeroBlur1 } from '../../assets/img/hero-blur-1.svg';
import { ReactComponent as HeroBlur2 } from '../../assets/img/hero-blur-2.svg';
import { ReactComponent as HeroBlur3 } from '../../assets/img/hero-blur-3.svg';
import { FaTwitter} from "react-icons/fa";

const Hero = () => {
    return (
        <div className="theme-hero ">
            <div className="position-absolute top-0 end-0 ">
                <HeroBlur1 />
            </div>
            <div className="position-absolute top-0 end-0">
                <HeroBlur2 />
            </div>
            <div className="position-absolute top-0 start-0">
                <HeroBlur3 />
            </div>
            <div className="container position-relative z-1">
                <div className="row align-items-center">
                    <div className="col-lg-7">
                        <div className="section-title mb-sm">
                            <div className="main-title" data-aos="fade-up" data-aos-duration="1000"><p>Introducing <span style={{ color: '#15D6B9' }}>Callop.</span></p>
                                <p> OTC Option Trading.</p></div>
                            <div className="sub-title w-100" data-aos="fade-up" data-aos-duration="1050">
                                Buy, sell, and trade options for any ERC-20 token.
                        </div>
                        </div>
                        <div>
                            <div className="action">
                                <a href="https://twitter.com/Callopx" className="btn btn-primary" data-aos="fade-up" data-aos-duration="1100">Coming Soon</a>
                            </div>
                            <div className="action">
                                <div className="social-links" data-aos="fade-up" data-aos-duration="1200">
                                    <a href="https://twitter.com/Callopx"><FaTwitter /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="mt-5 mt-lg-0">
                            <div className="d-flex justify-content-center" data-aos="fade-right" data-aos-duration="800">
                                <HeroVector />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Hero