import howImage1 from '../../assets/img/how-1.png';
import howImage2 from '../../assets/img/how-2.png';
import howImage3 from '../../assets/img/how-3.png';

const How = () => {
    return (
        <div className="section-gap position-relative z-2">
            {/* <!-- SECTION TITLE --> */}
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="section-title text-center">
                            <div className="main-title" data-aos="fade-up" data-aos-duration="800">100% Hands Off</div>
                            <div className="sub-title" data-aos="fade-up" data-aos-duration="1000">
Callop uses an OTC system to create seperate, isolated trades directly between a buyer and a seller.             </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- ./ SECTION TITLE --> */}

            <div className="container">
                <div className="row g-4">
                    <div className="col-lg-4">
                        <div className="card-wrap" data-aos="fade-up" data-aos-duration="800">
                            <div className="card-how text-center">
                                <div className="card-image">
                                    <img src={howImage1} alt="" className="img-fluid" />
                                </div>
                                <div className="card-title">Oracle Free</div>
                                <div className="card-text">
                                    No oracles means no market manipulation.
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="card-wrap" data-aos="fade-up" data-aos-duration="800" data-aos-delay="100">
                            <div className="card-how text-center">
                                <div className="card-image">
                                    <img src={howImage2} alt="" className="img-fluid" />
                                </div>
                                <div className="card-title">Peer to Peer</div>
                                <div className="card-text">
                                    No vaults or pools. All trades are peer to peer.
                        </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="card-wrap" data-aos="fade-up" data-aos-duration="800" data-aos-delay="200">
                            <div className="card-how text-center">
                                <div className="card-image">
                                    <img src={howImage3} alt="" className="img-fluid" />
                                </div>
                                <div className="card-title">ERC-20 Tokens</div>
                                <div className="card-text">
                                    Users can create a trade for any ERC-20 token.
                        </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default How