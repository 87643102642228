import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import HomePage from './pages/Home';

function App() {

  useEffect(() => {
    // AOS
    const targets = document.querySelectorAll('[data-aos]');
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          console.log(entry.target.classList.add('aos-animate'));
          observer.unobserve(entry.target);
        }
      });
    });
    targets.forEach(target => observer.observe(target));
  }, []);


  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
    </Routes>
  );
}

export default App;