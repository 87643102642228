import { ReactComponent as RoadmapWave } from '../../assets/img/roadmap-wave.svg';

const Roadmap = () => {
  return (
    <div className="section-gap position-relative z-2">
      {/* <!-- SECTION TITLE --> */}
      <div className="container position-relative z-1">
          <div className="row justify-content-center">
              <div className="col-lg-8">
                  <div className="section-title text-center">
                      <div className="main-title" data-aos="fade-up" data-aos-duration="800">How it works</div>
                  </div>
              </div>
          </div>
      </div>
      {/* <!-- ./ SECTION TITLE --> */}

      <div className="container">
        <div className="row g-4">
          <div className="col-12 d-none d-xl-block">
            <div className="d-flex justify-content-center" data-aos="fade-right" data-aos-duration="800">
              <RoadmapWave />
            </div>
          </div>
          <div className="col-lg-6 col-xl-3">
            <div className="card-roadmap" data-aos="fade-up" data-aos-duration="800">
              <div className="card-head">
                <span className="qs">1.</span>
                <span className="year">BID</span>
                <span className="line"></span>
              </div>
              <div className="card-content">
                A user can create a bid. They pick a token, a strike price, expiration, and the premium. To create the bid they post their collatoral. 
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-xl-3">
            <div className="card-roadmap" data-aos="fade-up" data-aos-duration="800">
              <div className="card-head">
                <span className="qs">2.</span>
                <span className="year">FILL</span>
                <span className="line"></span>
              </div>
              <div className="card-content">
                A buyer sees a bid they like and fill the trade by paying the premium. The trade is locked in, and the premium goes to the seller and the collatoral is locked.
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-xl-3">
            <div className="card-roadmap" data-aos="fade-up" data-aos-duration="800">
              <div className="card-head">
                <span className="qs">3.</span>
                <span className="year">EXECUTE</span>
                <span className="line"></span>
              </div>
              <div className="card-content">
                The price of the token goes far beyond the strike price into a profitable position for the buyer and they decide to execute. They are able to purchase the token collatoral locked from the seller for the agreed upon strike price.
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-xl-3">
            <div className="card-roadmap" data-aos="fade-up" data-aos-duration="800">
              <div className="card-head">
                <span className="qs">4.</span>
                <span className="year">PROFIT</span>
                <span className="line"></span>
              </div>
              <div className="card-content">
                After executing the trade, the buyer was able to purchase the token at a far below market price. They then sell it directly into the uniswap pool for a hefty profit.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Roadmap