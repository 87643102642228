import Accordion from 'react-bootstrap/Accordion';

function DefiAccordion() {
    return (
        <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0" data-aos="fade-up" data-aos-duration="800">
                <Accordion.Header>What type of trades are offered</Accordion.Header>
                <Accordion.Body>
Currently the only offerings are OTC call option. We will add more in the future.                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1" data-aos="fade-up" data-aos-duration="800">
                <Accordion.Header>What is a call option?</Accordion.Header>
                <Accordion.Body>
<p>A call option allows a user to sell the rights but not the obligation to buy a token at a certain price by a certain date. </p>
<p>For example. A user can offer to sell 200,000 $RLB for .3 cents per coin within the next 3 months. The rights to this trade is sold for .5 eth.</p>

<p> At the current price, $RLB is only around ~.15 cents so if the buyer were to execute this trade, at the current price, they would have buy at .3 cents even though the current price is .15 which is a terrible deal!</p>
<p> However options executions are rights and not the obligation. The buyer does not have to execute the trade unless they want to and can allow it to expire worthless in this situation.</p> 
<p> But if $RLB goes to .5 cents within the next 3 months, the buyer can execute the trade to buy at .3 cents making them quite a big profit! This is a very top level explanation and can be explained more here.</p>          </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2" data-aos="fade-up" data-aos-duration="800">
                <Accordion.Header>I want to buy a call for a token but no one is offering it?</Accordion.Header>
                <Accordion.Body>
If you are looking to buy an option for a certain token but it is not available, you can create the otherside of the trade first. By supplying the premium (cost of the option) a user can fill your bid and create the trade for you if they find the price/conditions acceptable.</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3" data-aos="fade-up" data-aos-duration="800">
                <Accordion.Header>How do i make sure my trade is guaranteed </Accordion.Header>
                <Accordion.Body>
                    All trades are covered. The seller must lock their tokens for the duration of the trade. The buyer must pay the premium which goes directly to the seller immedietly. 
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4" data-aos="fade-up" data-aos-duration="800">
                <Accordion.Header>How can I stay updated on the latest developments on the platform?</Accordion.Header>
                <Accordion.Body>
                    Check out twitter to stay updated!                
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}

export default DefiAccordion;