import React from 'react'
import Footer from '../../Components/Footer';
import Cta from './Cta';
import Faq from './Faq';
import Features from './Features';
import Hero from './Hero';
import How from './How';
import Roadmap from './Roadmap';

const Home = () => {
  return (
    <div>
      <Hero/>
      <Roadmap/>
      <How/>
      <Features/>
      <Faq/>
      <Cta/>
      <Footer/>
    </div>
  )
}

export default Home;