
import { ReactComponent as FooterLine } from '../assets/img/footer-line.svg';
import { ReactComponent as FooterBlur } from '../assets/img/footer-blur.svg';

const Footer = () => {
  return (
    <div className="theme-footer position-relative z-1">
        <div className="position-absolute top-0 start-50 translate-middle-x z-n1 h-100">
            <FooterBlur className="h-100" />
        </div>
        <div className="footer-line d-flex justify-content-center">
            <FooterLine />
        </div>
        <div className="footer-bottom">
            <div className="container">
                <div className="d-flex flex-column flex-lg-row gap-3 align-items-center justify-content-between">
                    <div className="footer-copyright">
                        &copy; Callopx All right Reserved 
                    </div>
                    <div className="footer-menu d-flex gap-4">
                        <a href="/#">Terms of Service</a>
                        <a href="/#">Privacy Policy</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Footer