import React from 'react'

const DefiCta = () => {
  return (
    <div className="theme-cta">
        <div className="section-title text-center mb-sm">
            <div className="main-title">Coming Soon.</div>
            <div className="sub-title w-100">
                Follow us on Twitter and keep updated!
            </div>
        </div>
        <div className="d-flex justify-content-center">
            <a href="https://twitter.com/Callopx" className="btn btn-dark rounded-pill">Get Updated</a>
        </div>
    </div>
  )
}

export default DefiCta;