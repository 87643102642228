import React from 'react'
import DefiAccordion from '../../Components/DefiAccordion'

const Faq = () => {
  return (
    <div className="section-gap">
        {/* <!-- SECTION TITLE --> */}
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-12">
                    <div className="section-title">
                        <div className="main-title" data-aos="fade-up" data-aos-duration="800">Frequently Asked Question</div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- ./ SECTION TITLE --> */}

        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-9">
                    <DefiAccordion />
                </div>
            </div>
        </div>
    </div>
  )
}

export default Faq