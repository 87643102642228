import feature1 from '../../assets/img/feat-1.png';
import feature2 from '../../assets/img/feat-2.png';
import feature3 from '../../assets/img/feat-3.png';
import feature4 from '../../assets/img/feat-4.png';
import feature5 from '../../assets/img/feat-5.png';
import feature6 from '../../assets/img/feat-6.png';

const Features = () => {
    return (
        <div className="section-gap position-relative z-2">
            {/* <!-- SECTION TITLE --> */}
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="section-title text-center">
                            <div className="main-title" data-aos="fade-up" data-aos-duration="800">Features</div>
                            <div className="sub-title" data-aos="fade-up" data-aos-duration="800">Offering new state of the art solutions to onchain trading</div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- ./ SECTION TITLE --> */}

            <div className="container">
                <div className="row g-5">
                    <div className="col-lg-6 col-xl-5 offset-xl-1">
                        <div className="card-feature" data-aos="fade-up" data-aos-duration="800">
                            <div className="icon-square icon-dark">
                                <img src={feature1} alt="" className="img-fluid" />
                            </div>
                            <div className="feature-content">
                                <div className="feature-title">Peer to Peer</div>
                                <div className="feature-text">
All trades are OTC. No orderbook, pools, or matching. Each trade is directly done between two parties.                                 </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-xl-5 offset-xl-1">
                        <div className="card-feature" data-aos="fade-up" data-aos-duration="800">
                            <div className="icon-square icon-dark">
                                <img src={feature2} alt="" className="img-fluid" />
                            </div>
                            <div className="feature-content">
                                <div className="feature-title">Transparent </div>
                                <div className="feature-text">
                                    View transaction history and track your assets in real-time.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-xl-5 offset-xl-1">
                        <div className="card-feature" data-aos="fade-up" data-aos-duration="800">
                            <div className="icon-square icon-dark">
                                <img src={feature3} alt="" className="img-fluid" />
                            </div>
                            <div className="feature-content">
                                <div className="feature-title">100% Guaranteed  </div>
                                <div className="feature-text">
Each trade is 100% covered by the seller and all premiums must be paid immedietly.                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-xl-5 offset-xl-1">
                        <div className="card-feature" data-aos="fade-up" data-aos-duration="800">
                            <div className="icon-square icon-dark">
                                <img src={feature4} alt="" className="img-fluid" />
                            </div>
                            <div className="feature-content">
                                <div className="feature-title">Customizable</div>
                                <div className="feature-text">
                                Unlimited customizability to create a trade for any type of scenario.  
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-xl-5 offset-xl-1">
                        <div className="card-feature" data-aos="fade-up" data-aos-duration="800">
                            <div className="icon-square icon-dark">
                                <img src={feature5} alt="" className="img-fluid" />
                            </div>
                            <div className="feature-content">
                                <div className="feature-title">Accessibility </div>
                                <div className="feature-text">
                                    Easily access a wider range of trading not available anywhere else.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-xl-5 offset-xl-1">
                        <div className="card-feature" data-aos="fade-up" data-aos-duration="800">
                            <div className="icon-square icon-dark">
                                <img src={feature6} alt="" className="img-fluid" />
                            </div>
                            <div className="feature-content">
                                <div className="feature-title">Any ERC-20</div>
                                <div className="feature-text">
                                    Works for any ERC20 token including crosschain.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Features