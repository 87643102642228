import DefiCta from "../../Components/DefiCta";


const Cta = () => {
  return (
    <div className="section-gap pt-0 position-relative z-1">
      <div className="container position-relative z-1">
          <div className="row">
              <div className="col-lg-12" data-aos="fade-up" data-aos-duration="800">
                  <DefiCta />
              </div>
          </div>
      </div>
    </div>
  )
}

export default Cta